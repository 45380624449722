import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    // This is for the scroll top when click on any router link
    routes: [
        {
            path: '/auth',
            component: () => import('@/layouts/blank-layout/Blanklayout'),
            meta: {
                guestOnly: true
            },
            children: [
                {
                    name: 'login',
                    path: 'login',
                    component: () => import('@/views/authentication/FullLogin'),
                },
                {
                    name: 'register',
                    path: 'register',
                    component: () => import('@/views/authentication/FullRegister'),
                },
                {
                    name: 'forgot',
                    path: 'password/reset',
                    component: () => import('@/views/authentication/FullForgot'),
                },
            ]
        },
        // {
        //     name: 'error',
        //     path: '*',
        //     component: () => import('@/views/Error'),
        // }
    ],
})
