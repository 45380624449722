let routes = [

    {
        path: '/',
        redirect: 'dashboard',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                name: 'user.dashboard',
                path: 'dashboard',
                component: () => import('@/views/user/Dashboard'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.offers',
                path: 'offers',
                component: () => import('@/views/user/offer/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.domains',
                path: 'domains',
                component: () => import('@/views/user/domain/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.links',
                path: 'links',
                component: () => import('@/views/user/link/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.cpaNetworks',
                path: 'cpa-networks',
                component: () => import('@/views/user/cpaNetwork/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.landingDesigns',
                path: 'landing-designs',
                component: () => import('@/views/user/landingDesign/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.landings',
                path: 'landings',
                component: () => import('@/views/user/landing/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.billing',
                path: 'billing',
                component: () => import('@/views/user/billing/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'user.integrations',
                path: 'integrations',
                component: () => import('@/views/user/integration/Index'),
                meta: {
                    requiresAuth: true
                }
            },

        ]
    },
]

export default routes;
