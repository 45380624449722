import route from '@/route'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuebar from 'vuebar'
import './plugins/base'
import VueSkycons from 'vue-skycons';
import axiosApi from 'axios';
import Lang from "lang.js";
import Messages from "@/lang/Messages";
import vuetify from './plugins/vuetify'
import moment from "moment";
import {VueMasonryPlugin} from "vue-masonry";

const axios = axiosApi.create({
    withCredentials: true,
    headers:{ 'Accept':'application/json', 'X-Requested-With': 'XMLHttpRequest'}
});
// intercept 401 err redirect to login
axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    console.log(error.response)
    if (error && error.response && error.response.status === 401) {
        console.log(router.currentRoute);
        if (router.currentRoute.name === null){
            // window.location.href = '/';
        }
    }
    if (error && error.response && error.response.status === 419) {
        console.log(router.currentRoute);
        window.location.href = "/";
    }
    return Promise.reject(error)
})
window.axios = axios;
Vue.prototype.route = route;
// 1. get csrf
axios.get(route('csrf')).then( () => {
    // 2. get auth
    window.axios.get(route('isAuthorized')).then((response) => {
        store.commit('auth_success', response.data);
    }).catch(() => {
        store.commit('auth_error');
        // window.location.href = '/';
        console.log(router);
    }).then(() => {

        Vue.use(VueSkycons, {
            color: '#1e88e5'
        });

        Vue.config.productionTip = false
        Vue.use(Vuebar);
        Vue.use(VueMasonryPlugin);

        // 3. get locale
        store.dispatch('getLocale').then().catch().then(() => {
            console.log(store.getters.curlocale)
            const default_locale = store.getters.curlocale;
            const messages = Messages;
            const lang = new Lang({messages, locale: default_locale});

            Vue.prototype.lang = lang;
            Vue.prototype.trans = (key, rep = null, loc = null) => {
                if (!loc) {
                    loc = store.getters.curlocale;
                }
                _.forEach(['ru', 'en'], (locale) => {
                    if (!lang.has(key, locale)) {
                        console.error('Missing translation ' + key + ' In ' + locale);
                    }
                })
                return lang.get(key, rep, loc)
            };
            vuetify.framework.lang.current = store.getters.curlocale;
            console.log(vuetify);

            Object.defineProperty(Vue.prototype, "$bus", {
                get: function () {
                    return this.$root.bus;
                }
            });

            if (store.getters.isAdmin) {
                router.addRoutes(require('@/routerAdmin').default)
                router.addRoutes(require('@/routerUser').default)
            } else {
                router.addRoutes(require('@/routerUser').default)
            }
            if (store.getters.isCpaNetworkManager) {
                router.addRoutes(require('@/routerCpaNetworkManager').default)
            }
            if (store.getters.isMaster) {
                router.addRoutes(require('@/routerMaster').default)
            }


            router.beforeEach((to, from, next) => {
                if (to.matched.some(record => record.meta.requiresAuth)) {
                    if (store.getters.isLoggedIn) {
                        next()
                        return
                    }
                    next({name: 'login'})
                } else if (to.matched.some(record => record.meta.guestOnly)) {
                    if (!store.getters.isLoggedIn) {
                        next()
                        return
                    }
                    next({name: 'dashboard'})
                } else {
                    next()
                }
            });

            Vue.filter('mysqlDate', function (value) {
                if (!value) return ''
                value = value.toString()
                return moment(value).format('YYYY-MM-DD HH:mm:ss');
            });
            Vue.filter('dateFormat', function (value, format) {
                if (!value) return ''
                value = value.toString()
                return moment(value).format(format);
            });
            Vue.filter('user', function (value) {
                if (!value) return ''
                return `${value.id} :: ${value.email}`;
            });

            new Vue({
                vuetify,
                store,
                router,
                data: {
                    bus: new Vue({}) // Here we bind our event bus to our $root Vue model.
                },
                render: h => h(App)
            }).$mount('#app')
        })

    })

})



