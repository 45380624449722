let routes = [

    {
        path: '/master-management',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            // {
            //     name: 'cpaNetworkManager.users',
            //     path: ':id/users',
            //     component: () => import('@/views/cpaNetworkManager/user/Index'),
            //     meta: {
            //         requiresAuth: true
            //     }
            // },
            {
                name: 'master.users',
                path: 'users',
                component: () => import('@/views/master/user/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'master.apiSettings',
                path: 'api-settings',
                component: () => import('@/views/master/apiSettings/Index'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
]

export default routes;
