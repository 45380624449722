let routes = [

    {
        path: '/network-management',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            // {
            //     name: 'cpaNetworkManager.users',
            //     path: ':id/users',
            //     component: () => import('@/views/cpaNetworkManager/user/Index'),
            //     meta: {
            //         requiresAuth: true
            //     }
            // },
            {
                name: 'cpaNetworkManager.offers',
                path: ':id/offers',
                component: () => import('@/views/cpaNetworkManager/offer/Index'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
]

export default routes;
