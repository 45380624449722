<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />
      <snack-notification></snack-notification>
  </v-app>

</template>

<script>


import SnackNotification from "@/components/commonComponents/SnackNotification";
export default {
    name: 'App',
    components: {
        SnackNotification

    },
    created() {
        this.$store.dispatch('getLocale');
        axios.interceptors.response.use(function (response) {
            return response
        },  (error) => {
            console.log(error.response)
            if (error.response.status === 401) {
                if(this.$store.getters.isLoggedIn){
                    this.$store.dispatch('logout')
                }
            }
            return Promise.reject(error)
        })
    },
    methods: {
    },


};
</script>
