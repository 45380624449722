let routes = [
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/layouts/admin-layout/Layout'),
        children: [
            {
                name: 'admin.users',
                path: 'users',
                component: () => import('@/views/admin/user/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.masters',
                path: 'masters',
                component: () => import('@/views/admin/master/Index'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                name: 'admin.masters.users',
                path: 'masters/:id',
                component: () => import('@/views/admin/master/Users'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.offers',
                path: 'offers',
                component: () => import('@/views/admin/offer/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.landingDesigns',
                path: 'landing-designs',
                component: () => import('@/views/admin/landingDesign/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.languages',
                path: 'languages',
                component: () => import('@/views/admin/language/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.countries',
                path: 'countries',
                component: () => import('@/views/admin/country/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.landings',
                path: 'landings',
                component: () => import('@/views/admin/landing/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.cpaNetworks',
                path: 'cpa-networks',
                component: () => import('@/views/admin/cpaNetwork/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.links',
                path: 'links',
                component: () => import('@/views/admin/link/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.domains',
                path: 'domains',
                component: () => import('@/views/admin/domain/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.plans',
                path: 'plans',
                component: () => import('@/views/admin/plan/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.promoCodes',
                path: 'promo-codes',
                component: () => import('@/views/admin/promoCode/Index'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                name: 'admin.promoCodes.items',
                path: 'promo-codes/:id/items',
                component: () => import('@/views/admin/promoCode/Items'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.promoCodes.activations',
                path: 'promo-code-items/:id/activations',
                component: () => import('@/views/admin/promoCode/Activations'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'admin.transactions',
                path: 'transactions',
                component: () => import('@/views/admin/transaction/Index'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
]

export default routes;
