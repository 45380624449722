import Vue from 'vue'
import Vuex from 'vuex'
import route from "@/route";
import router from "@/router";
import * as _ from 'lodash';
const env = require('@/env.json');

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white',
        SidebarBg: '',
        auth: false,
        user: null,
        locale: 'en',
        vuetify: null,
        snackbar: {
            trigger: false,
            text: null,
            type: null,
        },
        snackbarText: null,
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },
        auth_success(state, user){
            state.auth = true
            state.user = user
        },
        auth_error(state){
            state.auth = false
            state.user = null
        },
        setLocale(state, locale){
            state.locale = locale;
        },
        setVuetify(state, vuetify){
            state.vuetify = vuetify;
        },
        setSnackBar(state, payload){
            state.snackbar = {
                trigger: true,
                text: payload.text,
                type: payload.type
            };
        },
        unsetSnackBar(state){
            state.snackbar.trigger = false;
        }
    },
    actions: {
        getAuth(context) {
            return new Promise((resolve, reject) => {
                axios.get(route('isAuthorized')).then( response => {
                    context.commit('auth_success', response.data);
                    // if (getters.isAdmin){
                    //     router.addRoutes(require('@/routerAdmin').default)
                    // }
                    resolve(response.data);
                }).catch( () => {
                    context.commit('auth_error');
                    reject('');
                })
            })
        },
        logout(context) {
            return new Promise((resolve, reject) => {
                axios.post(route('logout')).then(() => {
                    context.commit('auth_error');
                    router.push({name: 'login'});
                    resolve();
                }).catch(() => {
                    context.commit('auth_error');
                    router.push({name: 'login'});
                    reject();
                });
            });
        },
        getLocale(context) {
            return new Promise((resolve, reject) => {
                let locale = localStorage.getItem('locale');
                if (locale){
                    context.commit('setLocale', locale);
                    context.dispatch('setLocale', locale)
                        .then( () => resolve(locale))
                        .catch( (err) => reject(err));
                } else {
                    axios.get(route('getLocale')).then((response) => {
                        context.commit('setLocale', response.data.locale);
                        resolve(response.data.locale);
                    }).catch( (err) => {
                        console.log(err.response);
                        reject(err);
                    })
                }
            })
        },
        setLocale(context, locale) {
            return new Promise((resolve, reject) => {
                axios.post(route('setLocale'), {locale: locale}).then((response) => {
                    context.commit('setLocale', response.data.locale);
                    localStorage.setItem('locale', response.data.locale)
                    // window.location.reload();
                    resolve(response);
                }).catch( (err) => {
                    console.log(err.response);
                    reject(err);
                })
            })
        },
        forceLocale(context, locale) {
            return new Promise((resolve, reject) => {
                context.dispatch('setLocale', locale)
                    .then( () => {
                        window.location.reload();
                        resolve(locale)
                    } )
                    .catch( (err) => reject(err))
            })
        },
        reachGoal(context, goalName) {
            return new Promise((resolve) => {
                gtag('event', goalName, {event_category: 'user'})
                ym(66924082,'reachGoal',goalName, {}, () => {
                    resolve();

                })
            })
        },
        showSnack(context, payload) {
            context.commit('setSnackBar', payload);
        },
        hideSnack(context) {
            context.commit('unsetSnackBar');
        },
    },
    getters: {
        user: state => state.user,
        isLoggedIn: state => !!state.auth,
        curlocale: state => state.locale,
        snackbarTrigger: state => state.snackbar.trigger,
        snackbarText: state => state.snackbar.text,
        snackbarType: state => state.snackbar.type,
        whiteLabel: () => {
            if (env.whiteLabel[window.location.hostname]){
                return env.whiteLabel[window.location.hostname];
            } else {
                return null;
            }
        },

        isAdmin: state => {
            if (!state.user || !state.user.roles || !state.user.roles.length) return false;
            return !!_.filter(state.user.roles, {name: 'admin'}).length;
        },
        isCpaNetworkManager: state => {
            if (!state.user || !state.user.cpa_network_managers || !state.user.cpa_network_managers.length) return false;
            return true;
        },
        isMaster: state => {
            if (!state.user || !state.user.is_master) return false;
            return true;
        },
        vendorId: state => {
            if (!state.user || !state.user.vendor_id) return false;
            return state.user.vendor_id;
        },
        vendorAlias: state => {
            if (!state.user || !state.user.vendor_alias) return false;
            return state.user.vendor_alias;
        },
        vendorRestrictions: state => {
            if (!state.user || !state.user.vendor_restrictions) return false;
            return state.user.vendor_restrictions;
        },
        homeRouteName: (state, getters) => {
            if(getters.isAdmin){
                return 'admin'
            } else {
                return 'user.dashboard'
            }
        },
    }
})
