<template>
    <v-snackbar
            v-model="trigger"
            :timeout="5000"
            bottom
            :color="color"
    >
        <v-icon v-if="type === 'success'">mdi-check</v-icon>
        <v-icon v-if="type === 'error'">mdi-alert-circle</v-icon>
        {{ $store.getters.snackbarText }}

        <template v-slot:action="{ attrs }">
            <v-btn
                    text
                    icon
                    v-bind="attrs"
                    @click="$store.dispatch('hideSnack')"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "SnackNotification",
        computed: {
            type() {
                return this.$store.getters.snackbarType;
            },
            color(){
                const type = this.type;
                if (type === 'success'){
                    return 'green';
                } else if (type === 'error'){
                    return 'red';
                } else {
                    return 'blue darken-1'
                }
            },
            trigger: {
                get(){
                    return this.$store.getters.snackbarTrigger
                },
                set(value){
                    if (!value){
                        return this.$store.dispatch('hideSnack');
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>